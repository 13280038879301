var render = function () {
  var _vm$companyId;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('BCard', {
    attrs: {
      "id": "invoice-list",
      "body-class": "p-1"
    }
  }, [_c('div', {
    staticClass: "d-flex-center justify-content-start gap-1 bg-primary-gradient"
  }, [_c('IAmIcon', {
    staticClass: "p-1 icon-card",
    attrs: {
      "icon": "receipt",
      "size": "25"
    }
  }), _c('span', {
    staticClass: "font-medium-3 m-0 fw-700"
  }, [_vm._v(" " + _vm._s(_vm.$t('Invoice List')) + " ")])], 1), _c('InvoiceListFilter', {
    attrs: {
      "sort-by": _vm.sortBy,
      "is-sort-dir-desc": _vm.isSortDirDesc,
      "start-date": _vm.startDate,
      "end-date": _vm.endDate,
      "invoice-status": _vm.invoiceStatus,
      "release-status": _vm.releaseStatus,
      "send-mail-status": _vm.sendMailStatus,
      "signStatus": _vm.signStatus,
      "inv-number": _vm.invNumber,
      "reference-code": _vm.referenceCode,
      "agency-code": _vm.agencyCode,
      "buyer-name": _vm.buyerName,
      "tagsFilter": _vm.tagsFilter
    },
    on: {
      "update:sortBy": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:isSortDirDesc": function updateIsSortDirDesc($event) {
        _vm.isSortDirDesc = $event;
      },
      "update:is-sort-dir-desc": function updateIsSortDirDesc($event) {
        _vm.isSortDirDesc = $event;
      },
      "update:startDate": function updateStartDate($event) {
        _vm.startDate = $event;
      },
      "update:start-date": function updateStartDate($event) {
        _vm.startDate = $event;
      },
      "update:endDate": function updateEndDate($event) {
        _vm.endDate = $event;
      },
      "update:end-date": function updateEndDate($event) {
        _vm.endDate = $event;
      },
      "update:invoiceStatus": function updateInvoiceStatus($event) {
        _vm.invoiceStatus = $event;
      },
      "update:invoice-status": function updateInvoiceStatus($event) {
        _vm.invoiceStatus = $event;
      },
      "update:releaseStatus": function updateReleaseStatus($event) {
        _vm.releaseStatus = $event;
      },
      "update:release-status": function updateReleaseStatus($event) {
        _vm.releaseStatus = $event;
      },
      "update:sendMailStatus": function updateSendMailStatus($event) {
        _vm.sendMailStatus = $event;
      },
      "update:send-mail-status": function updateSendMailStatus($event) {
        _vm.sendMailStatus = $event;
      },
      "update:signStatus": function updateSignStatus($event) {
        _vm.signStatus = $event;
      },
      "update:sign-status": function updateSignStatus($event) {
        _vm.signStatus = $event;
      },
      "update:invNumber": function updateInvNumber($event) {
        _vm.invNumber = $event;
      },
      "update:inv-number": function updateInvNumber($event) {
        _vm.invNumber = $event;
      },
      "update:referenceCode": function updateReferenceCode($event) {
        _vm.referenceCode = $event;
      },
      "update:reference-code": function updateReferenceCode($event) {
        _vm.referenceCode = $event;
      },
      "update:agencyCode": function updateAgencyCode($event) {
        _vm.agencyCode = $event;
      },
      "update:agency-code": function updateAgencyCode($event) {
        _vm.agencyCode = $event;
      },
      "update:buyerName": function updateBuyerName($event) {
        _vm.buyerName = $event;
      },
      "update:buyer-name": function updateBuyerName($event) {
        _vm.buyerName = $event;
      },
      "update:tagsFilter": function updateTagsFilter($event) {
        _vm.tagsFilter = $event;
      },
      "update:tags-filter": function updateTagsFilter($event) {
        _vm.tagsFilter = $event;
      },
      "refetch-data": _vm.refetchData,
      "refetch-data-without-filters": function refetchDataWithoutFilters($event) {
        _vm.clearFilter();
        _vm.refetchData();
      }
    }
  }), _c('div', {
    staticClass: "w-100 d-flex-center justify-content-end gap-2 mb-50"
  }, [_c('BButton', {
    staticClass: "p-50",
    attrs: {
      "variant": "info",
      "size": "sm",
      "to": {
        name: 'apps-invoiceCreate'
      }
    }
  }, [_c('span', {
    staticClass: "text-nowrap d-flex-center gap-1"
  }, [_c('IAmIcon', {
    attrs: {
      "icon": "increase",
      "size": "16",
      "color": "white"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('invoice.create.title')) + " ")], 1)]), _c('BButton', {
    staticClass: "p-50",
    attrs: {
      "variant": "info",
      "size": "sm"
    },
    on: {
      "click": _vm.onExportHandle
    }
  }, [_c('span', {
    staticClass: "text-nowrap d-flex-center gap-1"
  }, [_c('IAmIcon', {
    attrs: {
      "icon": "download",
      "size": "16",
      "color": "white"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('invoice.btn.export')) + " ")], 1)]), _c('BButton', {
    staticClass: "p-50",
    attrs: {
      "variant": "info",
      "size": "sm"
    },
    on: {
      "click": _vm.onSignHandle
    }
  }, [_c('span', {
    staticClass: "text-nowrap d-flex-center gap-1"
  }, [_c('IAmIcon', {
    attrs: {
      "icon": "edit",
      "size": "16",
      "color": "white"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('invoice.btn.sign')) + " ")], 1)]), _c('BButton', {
    staticClass: "p-50",
    attrs: {
      "variant": "warning",
      "size": "sm"
    },
    on: {
      "click": _vm.onReleaseHandle
    }
  }, [_c('span', {
    staticClass: "text-nowrap d-flex-center gap-1"
  }, [_c('IAmIcon', {
    attrs: {
      "icon": "splitPassenger",
      "size": "16",
      "color": "white"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('invoice.btn.releaseInvoice')) + " ")], 1)]), _c('BButton', {
    staticClass: "p-50",
    attrs: {
      "variant": "danger",
      "size": "sm"
    },
    on: {
      "click": _vm.onCancelInvoiceHandle
    }
  }, [_c('span', {
    staticClass: "text-nowrap d-flex-center gap-1"
  }, [_c('IAmIcon', {
    attrs: {
      "icon": "trash",
      "size": "16",
      "color": "white"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('invoice.btn.cancelInvoice')) + " ")], 1)]), _c('BButton', {
    staticClass: "p-50 shadow-lg",
    attrs: {
      "variant": "flat-primary",
      "size": "sm"
    },
    on: {
      "click": _vm.onSettingHandle
    }
  }, [_c('span', {
    staticClass: "text-nowrap d-flex-center gap-1"
  }, [_c('IAmIcon', {
    attrs: {
      "icon": "setting",
      "size": "16"
    }
  })], 1)])], 1), _c('b-table', {
    ref: "refInvoiceListTable",
    staticClass: "position-relative",
    staticStyle: {
      "max-height": "70vh"
    },
    attrs: {
      "sticky-header": true,
      "items": _vm.fetchInvoices,
      "responsive": "",
      "primary-key": "id",
      "show-empty": "",
      "empty-text": _vm.$t('noMatchingResult'),
      "no-border-collapse": "",
      "small": "",
      "bordered": "",
      "busy": _vm.loadingList,
      "sort-desc": _vm.isSortDirDesc,
      "sort-by": _vm.sortBy,
      "fields": _vm.tableColumnInvoiceList,
      "head-row-variant": "primary"
    },
    on: {
      "update:sortDesc": function updateSortDesc($event) {
        _vm.isSortDirDesc = $event;
      },
      "update:sort-desc": function updateSortDesc($event) {
        _vm.isSortDirDesc = $event;
      },
      "update:sortBy": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "row-contextmenu": _vm.handleContextMenu
    },
    scopedSlots: _vm._u([_vm._l(_vm.tableColumnInvoiceList, function (column) {
      return {
        key: "head(".concat(column.key, ")"),
        fn: function fn(data) {
          return [_c('span', {
            key: column.key,
            staticClass: "text-nowrap"
          }, [column.key === 'checkbox' ? _c('div', [_c('b-form-checkbox', {
            attrs: {
              "name": "id-all-check-box",
              "checked": _vm.isAll
            },
            on: {
              "change": _vm.selectAll
            }
          })], 1) : _c('div', [_vm._v(_vm._s(_vm.$t("invoice.columns.".concat(data.column))))])])];
        }
      };
    }), {
      key: "table-busy",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex-center text-dark my-2 gap-2"
        }, [_c('b-spinner', {
          staticClass: "align-middle"
        }), _c('strong', [_vm._v(_vm._s(_vm.$t('loading')))])], 1)];
      },
      proxy: true
    }, {
      key: "cell(checkbox)",
      fn: function fn(row) {
        return [_c('b-form-checkbox', {
          attrs: {
            "name": "item-check-box",
            "inline": "",
            "checked": _vm.isChecked(row.item.id)
          },
          on: {
            "change": function change($event) {
              return _vm.chooseItem(row.item.id);
            }
          }
        })];
      }
    }, {
      key: "cell(symbol)",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(item.templateNumber) + _vm._s(item.symbol) + " ")];
      }
    }, {
      key: "cell(invNumber)",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('b-link', {
          staticClass: "nav-link text-primary hover fw-700",
          attrs: {
            "to": {
              name: 'apps-invoiceDetail',
              params: {
                id: item.id
              }
            }
          }
        }, [_c('span', [_vm._v(" " + _vm._s(item.invNumber))])])];
      }
    }, {
      key: "cell(buyerName)",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('div', {
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s(item.buyerName) + " ")])];
      }
    }, {
      key: "cell(buyerUnitName)",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('div', {
          staticClass: "width-150 text-justify"
        }, [_vm._v(" " + _vm._s(item.buyerUnitName) + " ")])];
      }
    }, {
      key: "cell(buyerAddress)",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('div', {
          staticClass: "width-150 text-justify"
        }, [_vm._v(" " + _vm._s(item.buyerAddress) + " ")])];
      }
    }, {
      key: "cell(paymentMethod)",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('div', {
          staticClass: "width-150 text-justify"
        }, [_vm._v(" " + _vm._s(_vm.$t("invoice.paymentMethodOptions.".concat(item.paymentMethod))) + " ")])];
      }
    }, {
      key: "cell(invoiceStatus)",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_c('BBadge', {
          staticClass: "px-75",
          attrs: {
            "variant": _vm.resolveInvoiceStatus(item.invoiceStatus),
            "pill": ""
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("invoice.invoiceStatus.".concat(item.invoiceStatus))) + " ")])];
      }
    }, {
      key: "cell(releaseStatus)",
      fn: function fn(_ref8) {
        var item = _ref8.item;
        return [_c('BBadge', {
          staticClass: "px-75",
          attrs: {
            "variant": _vm.resolveReleaseStatus(item.releaseStatus),
            "pill": ""
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("invoice.releaseStatus.".concat(item.releaseStatus))) + " ")])];
      }
    }, {
      key: "cell(signStatus)",
      fn: function fn(_ref9) {
        var item = _ref9.item;
        return [_c('BBadge', {
          staticClass: "px-75",
          attrs: {
            "variant": "light-".concat(_vm.resolveSignStatus(item.signedAt ? 'SIGNED' : 'UNSIGNED').color),
            "pill": ""
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("invoice.signStatus.".concat(item.signedAt ? 'SIGNED' : 'UNSIGNED'))) + " ")])];
      }
    }, {
      key: "cell(sendMailStatus)",
      fn: function fn(_ref10) {
        var _vm$resolveSendMailSt, _vm$resolveSendMailSt2;
        var item = _ref10.item;
        return [_c('div', {
          staticClass: "d-flex-center"
        }, [_vm.resolveSendMailStatus(item === null || item === void 0 ? void 0 : item.sendMailStatus) ? _c('BButton', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.top.window",
            value: _vm.$t("invoice.sendMailStatus.".concat(item.sendMailStatus)),
            expression: "$t(`invoice.sendMailStatus.${item.sendMailStatus}`)",
            modifiers: {
              "hover": true,
              "top": true,
              "window": true
            }
          }],
          staticClass: "p-25 rounded-circle cursor-default",
          attrs: {
            "variant": (_vm$resolveSendMailSt = _vm.resolveSendMailStatus(item.sendMailStatus)) === null || _vm$resolveSendMailSt === void 0 ? void 0 : _vm$resolveSendMailSt.color
          }
        }, [_c('IAmIcon', {
          attrs: {
            "icon": (_vm$resolveSendMailSt2 = _vm.resolveSendMailStatus(item.sendMailStatus)) === null || _vm$resolveSendMailSt2 === void 0 ? void 0 : _vm$resolveSendMailSt2.icon,
            "size": "18",
            "color": "white"
          }
        })], 1) : _vm._e()], 1)];
      }
    }, {
      key: "cell(createdAt)",
      fn: function fn(_ref11) {
        var item = _ref11.item;
        return [_c('div', {}, [_vm._v(" " + _vm._s(_vm.convertISODateTime(item.createdAt).date) + " ")])];
      }
    }, {
      key: "cell(totalAmount)",
      fn: function fn(_ref12) {
        var item = _ref12.item;
        return [_c('div', {
          staticClass: "text-right"
        }, [_vm._v(" " + _vm._s(_vm.formatCurrency(item.totalAmount)) + " ")])];
      }
    }, {
      key: "cell(tags)",
      fn: function fn(_ref13) {
        var item = _ref13.item;
        return [_c('div', _vm._l(item.tags, function (tag) {
          return _c('BBadge', {
            key: tag,
            attrs: {
              "variant": "light-info"
            }
          }, [_vm._v(" " + _vm._s(tag) + " ")]);
        }), 1)];
      }
    }, {
      key: "cell(action)",
      fn: function fn(_ref14) {
        var _vm$BUTTON_ACTION_PER, _vm$BUTTON_ACTION_PER2, _vm$BUTTON_ACTION_PER3;
        var item = _ref14.item,
          index = _ref14.index;
        return [_c('div', {
          staticClass: "d-flex-center justify-content-start gap-1"
        }, [_c('BButton', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.window.hover",
            value: _vm.$t('invoice.btn.detail'),
            expression: "$t('invoice.btn.detail')",
            modifiers: {
              "window": true,
              "hover": true
            }
          }],
          staticClass: "p-50 rounded-circle",
          attrs: {
            "variant": "flat-dark",
            "to": {
              name: 'apps-invoiceDetail',
              params: {
                id: item.id
              }
            }
          }
        }, [_c('IAmIcon', {
          attrs: {
            "icon": "editOutline",
            "size": "18",
            "color": "black"
          }
        })], 1), (_vm$BUTTON_ACTION_PER = _vm.BUTTON_ACTION_PERMISSIONS) !== null && _vm$BUTTON_ACTION_PER !== void 0 && (_vm$BUTTON_ACTION_PER2 = _vm$BUTTON_ACTION_PER[item.invoiceStatus]) !== null && _vm$BUTTON_ACTION_PER2 !== void 0 && (_vm$BUTTON_ACTION_PER3 = _vm$BUTTON_ACTION_PER2[item.releaseStatus]) !== null && _vm$BUTTON_ACTION_PER3 !== void 0 && _vm$BUTTON_ACTION_PER3.VIEW_PDF ? _c('BButton', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.window.hover",
            value: _vm.$t('invoice.btn.preview'),
            expression: "$t('invoice.btn.preview')",
            modifiers: {
              "window": true,
              "hover": true
            }
          }],
          staticClass: "p-50 rounded-circle",
          attrs: {
            "variant": "flat-dark"
          },
          on: {
            "click": function click($event) {
              return _vm.onViewInvoiceHandle(item.id);
            }
          }
        }, [_c('IAmIcon', {
          attrs: {
            "icon": "showOutline",
            "size": "18",
            "color": "black"
          }
        })], 1) : _vm._e(), _c('BButton', {
          staticClass: "p-50 rounded-circle",
          attrs: {
            "variant": "flat-dark"
          },
          on: {
            "click": function click(e) {
              return _vm.handleContextMenu(item, index, e);
            }
          }
        }, [_c('IAmIcon', {
          attrs: {
            "icon": "dotsHorizontal",
            "size": "18",
            "color": "black"
          }
        })], 1)], 1)];
      }
    }], null, true)
  }), _c('b-row', [_c('b-col', {
    staticClass: "d-flex align-items-center",
    attrs: {
      "cols": "6",
      "md": "4",
      "order": "1"
    }
  }, [_c('v-select', {
    staticClass: "per-page-selector d-inline-block mx-50",
    attrs: {
      "options": _vm.sizePerPageOptions,
      "clearable": false,
      "append-to-body": "",
      "calculate-position": _vm.withPopper
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(" " + _vm._s(data.label) + " / " + _vm._s(_vm.$t('page')) + " ")])];
      }
    }, {
      key: "selected-option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(" " + _vm._s(data.label) + " / " + _vm._s(_vm.$t('page')) + " ")])];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.sizePerPage,
      callback: function callback($$v) {
        _vm.sizePerPage = $$v;
      },
      expression: "sizePerPage"
    }
  })], 1), _c('b-col', {
    staticClass: "d-flex-center",
    attrs: {
      "cols": "12",
      "md": "4",
      "order": "3",
      "order-md": "2"
    }
  }, [_c('b-pagination', {
    staticClass: "mb-0 mt-1 mt-sm-0",
    attrs: {
      "total-rows": _vm.totalInvoices,
      "per-page": _vm.sizePerPage,
      "first-number": "",
      "last-number": "",
      "prev-class": "prev-item",
      "next-class": "next-item"
    },
    scopedSlots: _vm._u([{
      key: "prev-text",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "ChevronLeftIcon",
            "size": "18"
          }
        })];
      },
      proxy: true
    }, {
      key: "next-text",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "ChevronRightIcon",
            "size": "18"
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.currentPage,
      callback: function callback($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1), _c('b-col', {
    staticClass: "d-flex-center justify-content-end",
    attrs: {
      "cols": "6",
      "md": "4",
      "order": "2",
      "order-md": "3"
    }
  }, [_c('span', {
    staticClass: "text-muted"
  }, [_vm._v(" " + _vm._s(_vm.dataMeta.from) + " " + _vm._s(_vm.$t('paginationText.to')) + " " + _vm._s(_vm.dataMeta.to) + " " + _vm._s(_vm.$t('paginationText.outOf')) + " " + _vm._s(_vm.dataMeta.of) + " " + _vm._s(_vm.$t('paginationText.items')) + " ")])])], 1)], 1), _c('vue-context', {
    ref: "MenuRefForInvoiceList",
    attrs: {
      "lazy": ""
    }
  }, [_c('li', _vm._l(_vm.menuList, function (menu, menuIndex) {
    return _c('a', {
      key: menuIndex,
      staticClass: "d-flex gap-1",
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return menu.onClick(_vm.itemContext);
        }
      }
    }, [_c('IAmIcon', {
      attrs: {
        "icon": menu.icon,
        "size": "18"
      }
    }), _vm._v(" " + _vm._s(menu.name) + " ")], 1);
  }), 0)]), _c('ModalSelectColumns', {
    on: {
      "apply": _vm.onApplySelectColumnsHandle
    }
  }), _c('InvoiceCancelModal', {
    attrs: {
      "invoice-data": _vm.invoiceSelected
    },
    on: {
      "refetch": _vm.refetchData
    }
  }), _c('InvoiceSignModal', {
    attrs: {
      "invoice-data": _vm.invoiceSelected,
      "companyId": (_vm$companyId = _vm.companyId) !== null && _vm$companyId !== void 0 ? _vm$companyId : null
    },
    on: {
      "refetch": _vm.refetchData
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }